import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/services/subscription/subscription-service'
import {
  activitiesDimension,
  displayNameByProduct,
  forbiddenMonthlyPaymentExemptionDimension,
  getSelectedProducts,
  hasAcceptedDimension,
  offerInformationDimension,
  productSelectionDimensions,
  type commitmentDimension,
  type forbiddenMonthlyPaymentDimension,
  type PartialDimensionnedState,
  type paymentRecurrenceDimension,
  type quoteDimension,
} from '@orus.eu/dimensions'
import {
  Button,
  ButtonGroup,
  colorTokens,
  EmptyState,
  EndorsementConfirmDialog,
  Row,
  RowContainer,
  SidePanel,
  spacing,
  Text,
  useAsyncCallback,
  useDialogVisibility,
  useEnqueueToast,
  useLanguage,
} from '@orus.eu/pharaoh'
import { Fragment, memo, useCallback, useMemo, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { useCopyToClipboard } from '../../../../../lib/clipboard-util'
import { assert } from '../../../../../lib/errors'
import { useDownloadAgreedTerms } from '../../../../../lib/hooks/use-download-agreed-terms'
import { logger } from '../../../../../lib/logger'
import { validateEmail } from '../../../../../lib/validation'
import { useBuildUrl } from '../../../../../use-build-url'
import { LocalLoadingState } from '../../../../molecules/local-loading-state'
import {
  disabledReasonsTooltips,
  noEmailDisabledReasonTooltip,
  noPhoneDisabledReasonTooltip,
  noQuoteDisabledReasonTooltip,
} from '../../../../organisms/share-quote-button'
import { PricingDimensionField } from '../../common/backoffice-quote-editor-v2/fields/pricing-dimension-field'
import type { useEndorsementQuoteEditorState } from './platform-endorsement-quote-editor-state'

export type PlatformPendingEndorsementSidePanelState = PartialDimensionnedState<
  [
    typeof paymentRecurrenceDimension,
    typeof quoteDimension,
    typeof commitmentDimension,
    typeof forbiddenMonthlyPaymentDimension,
    typeof forbiddenMonthlyPaymentExemptionDimension,
    typeof activitiesDimension,
    typeof offerInformationDimension,
    typeof hasAcceptedDimension,
  ]
> &
  PartialDimensionnedState<typeof productSelectionDimensions>

export type PlatformPendingEndorsementSidePanelProps = {
  changes: ReturnType<typeof useEndorsementQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useEndorsementQuoteEditorState>['after']
  state: PlatformPendingEndorsementSidePanelState
  stateBefore: PlatformPendingEndorsementSidePanelState
  nonDimensionalDataAfter: SubscriptionNonDimensionalData
  setChanges: (changes: PlatformPendingEndorsementSidePanelState) => void
  toggleHighlightDimensions: () => void
  isHighlightDimensionsActive: boolean
  hasQuote: boolean
  currentEmail: string | undefined
  currentPhone: string | undefined
  subscriptionId: string
  endorsementId: string
}

export const PlatformPendingEndorsementSidePanel = memo(function PlatformPendingEndorsementSidePanel(
  props: PlatformPendingEndorsementSidePanelProps,
) {
  const language = useLanguage()
  const {
    state,
    stateBefore,
    changes,
    nonDimensionalDataAfter,
    setChanges,
    toggleHighlightDimensions,
    isHighlightDimensionsActive,
    hasQuote,
    currentEmail,
    currentPhone,
    subscriptionId,
    endorsementId,
  } = props

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true)

  const quote = state.quote

  const activitiesDisplayNames = state.activities?.map((activity) => activity.displayName)
  const products = getSelectedProducts(state)
  const productsDisplayNames = products.map((product) => displayNameByProduct[product])

  const offerDisplayNames = state.offerInformation?.products.map((product) => product.name)
  const hasChanges = Object.keys(props.changes).length > 0

  const disabledPaymentReasonMissingFieldsNames = nonDimensionalDataAfter.missingSubscriptionDimensionsNames

  const disabledPaymentReasonMissingFieldsSet = new Set(disabledPaymentReasonMissingFieldsNames)

  const allowedMissingFieldsSet = new Set(['hasAccepted'])
  const canHideEmptySubscriptionState =
    disabledPaymentReasonMissingFieldsSet.size <= allowedMissingFieldsSet.size &&
    [...disabledPaymentReasonMissingFieldsSet].every((x) => allowedMissingFieldsSet.has(x))

  const isCurrentEmailValid = useMemo(
    () => currentEmail && validateEmail(currentEmail, language),
    [currentEmail, language],
  )

  const { download: downloadAgreedTerms, downloadInProgress } = useDownloadAgreedTerms({
    subscriptionId,
    changes,
    email: currentEmail,
    endorsementId,
  })

  const handleDownloadClick = useCallback(() => {
    downloadAgreedTerms().catch((err) => {
      logger.warning('Unhandled error during download', err)
    })
  }, [downloadAgreedTerms])

  const { hide, show, visible } = useDialogVisibility('validate-endorsement')

  const { enqueueToast } = useEnqueueToast()

  const sendNewEndorsementEmailMutation = trpcReact.endorsement.sendNewEndorsementEmail.useMutation()

  const sendClientEndorsement = useAsyncCallback(async () => {
    sendNewEndorsementEmailMutation.mutate({ endorsementId, subscriptionId })
    enqueueToast('Avenant envoyé', { variant: 'success' })
    hide()
  }, [endorsementId, enqueueToast, hide, sendNewEndorsementEmailMutation, subscriptionId])

  return (
    <>
      <SidePanel
        title="Tarificateur"
        isSidePanelOpen={isSidePanelOpen}
        setIsSidePanelOpen={() => setIsSidePanelOpen(true)}
        setIsSidePanelClose={() => setIsSidePanelOpen(false)}
      >
        {quote ? (
          <>
            <PricingDimensionField
              state={state}
              beforeState={stateBefore}
              setChanges={setChanges}
              nonDimensionalDataAfter={nonDimensionalDataAfter}
            />
            <SidePanelSection>
              <Text variant="body2Medium">Partager l&apos;avenant</Text>
              <ButtonGroup>
                <QuoteSharingButton
                  endorsementId={endorsementId}
                  nonDimensionalData={props.nonDimensionalDataAfter}
                  hasChanges={hasChanges}
                  hasQuote={hasQuote}
                  hasEmail={!!currentEmail}
                  hasPhone={!!currentPhone}
                />
                <Button
                  variant="secondary"
                  size="small"
                  icon="arrow-down-to-line-regular"
                  title="Télécharger les conditions particulières"
                  disabled={downloadInProgress || !props.hasQuote || !isCurrentEmailValid || hasChanges}
                  onClick={handleDownloadClick}
                  ariaLabel="Télécharger les conditions particulières"
                />
              </ButtonGroup>
            </SidePanelSection>
            {canHideEmptySubscriptionState ? (
              <SidePanelSection>
                <Button
                  variant="primary"
                  size="small"
                  css={css`
                    flex-grow: 1;
                  `}
                  disabled={!hasQuote || hasChanges}
                  onClick={show}
                >
                  Envoyer l&apos;avenant
                </Button>
              </SidePanelSection>
            ) : (
              <EmptyState
                subtitle="Remplissez la partie Informations pour pouvoir signer et finaliser."
                illustration="folder_big"
                style="neutral"
              >
                <EmptyStateButton toggleFunction={toggleHighlightDimensions} active={isHighlightDimensionsActive} />
              </EmptyState>
            )}

            <SummaryBlock
              activities={activitiesDisplayNames}
              products={productsDisplayNames}
              offers={offerDisplayNames}
            />
          </>
        ) : (
          <EmptyState
            illustration="calculator_big"
            subtitle="Remplissez la partie Tarificateur pour obtenir un tarif."
            height="320px"
            style="neutral"
          >
            <EmptyStateButton toggleFunction={toggleHighlightDimensions} active={isHighlightDimensionsActive} />
          </EmptyState>
        )}
      </SidePanel>
      {visible ? <EndorsementConfirmDialog onSubmit={sendClientEndorsement} onClose={hide} /> : undefined}
    </>
  )
})

const EmptyStateButton = memo(function EmptyStateButton({
  toggleFunction,
  active,
}: {
  toggleFunction: () => void
  active: boolean
}) {
  return (
    <Button onClick={toggleFunction} size="small">
      {active ? 'Masquer les champs requis' : 'Afficher les champs requis'}
    </Button>
  )
})

export const SummaryBlock = memo(function SummaryBlock({
  activities,
  products,
  offers,
}: {
  activities?: string[]
  products?: string[]
  offers?: string[]
}) {
  const sections: { title: string; elements: string[] | undefined }[] = [
    { title: 'Activité', elements: activities },
    { title: 'Produit d’assurance', elements: products },
    { title: 'Offre personnalisée', elements: offers },
  ]

  return (
    <SidePanelSection>
      <Text variant="body2Medium">Récapitulatif</Text>
      <RowContainer variant="border" size="small">
        {sections.map((section) =>
          section.elements ? (
            <Row
              variant="descriptive"
              key={section.title}
              title={section.title}
              titleVariant="body2Medium"
              titleColor={colorTokens['color-text-base-main']}
              subtitleVariant="body2"
              subtitleColor={colorTokens['color-text-base-basic']}
              subtitle={
                <>
                  {section.elements.map((element, index) => (
                    <Fragment key={index}>
                      {element}
                      <br />
                    </Fragment>
                  ))}
                </>
              }
            />
          ) : null,
        )}
      </RowContainer>
    </SidePanelSection>
  )
})

const SidePanelSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${spacing[30]};
`

type QuoteSharingButtonProps = {
  nonDimensionalData: SubscriptionNonDimensionalData
  endorsementId: string
  hasChanges: boolean
  hasQuote: boolean
  hasEmail: boolean
  hasPhone: boolean
}

const QuoteSharingButton = memo<QuoteSharingButtonProps>(function QuoteSharingButton(props) {
  const { enabled, tooltip } = getQuoteSharingButtonConfiguration(props)
  const { enqueueToast } = useEnqueueToast()
  const { buildUrl } = useBuildUrl()

  const { copyToClipboard } = useCopyToClipboard()
  const { data: token } = trpcReact.endorsement.getEndorsementToken.useQuery({ endorsementId: props.endorsementId })

  const copyLink = useCallback(() => {
    assert(token, 'token should be defined')
    copyToClipboard(
      buildUrl({
        to: '/s/e-v2/$token',
        params: { token },
      }),
    )
    enqueueToast("Lien de l'avenant copié", { variant: 'success' })
  }, [token, copyToClipboard, buildUrl, enqueueToast])

  if (!token) return <LocalLoadingState />

  return (
    <Button
      variant="secondary"
      size="small"
      icon="link-regular"
      avatarPosition="left"
      disabled={!enabled}
      onClick={copyLink}
      css={css`
        flex-grow: 1;
      `}
      title={tooltip}
    >
      Copier le lien
    </Button>
  )
})

function getQuoteSharingButtonConfiguration({
  hasChanges,
  hasQuote,
  hasEmail,
  hasPhone,
  nonDimensionalData,
}: QuoteSharingButtonProps): {
  tooltip: string
  enabled: boolean
} {
  if (hasChanges) {
    return {
      enabled: false,
      tooltip: disabledReasonsTooltips['unsaved-changes'],
    }
  }

  const canGenerateDraftAgreedTerms = nonDimensionalData.canGenerateAgreedTermsDraft
  if (canGenerateDraftAgreedTerms.type === 'cannot-generate') {
    return {
      enabled: false,
      tooltip: canGenerateDraftAgreedTerms.reason,
    }
  }

  if (!hasQuote) {
    return {
      enabled: false,
      tooltip: noQuoteDisabledReasonTooltip,
    }
  }
  if (!hasEmail) {
    return {
      enabled: false,
      tooltip: noEmailDisabledReasonTooltip,
    }
  }
  if (!hasPhone) {
    return {
      enabled: false,
      tooltip: noPhoneDisabledReasonTooltip,
    }
  }

  return {
    enabled: true,
    tooltip: 'Copier le lien à partager avec le client',
  }
}
